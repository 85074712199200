import { h, Fragment, cloneElement } from 'preact'
import {
  useRef,
  useCallback,
  useEffect,
  useErrorBoundary,
} from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'

import isMobile from 'lib/isMobile'
import metaKeyCharacter from 'lib/metaKeyCharacter'
import history from 'lib/history'
import { useMyPublicProfile } from 'lib/membershipAppStateHooks'
import useOnScroll from 'lib/useOnScrollHook'
import usePageKeyPress from 'lib/usePageKeyPressHook'
import useIsPWA from 'lib/useIsPWAHook'
import useToggle from 'lib/useToggleHook'
import { publicProfileToPathname } from 'lib/publicProfiles'
import useOnlineStatus from 'lib/useOnlineStatusHook'
import { useRecentOrganizations } from 'lib/recentOrganizations'

import { useCurrentUser } from 'resources/auth'
import { logout } from 'resources/auth'

import AppError from 'components/AppError'
import InterfaceHelp, { useInterfaceHelpVisibility } from 'components/InterfaceHelp'
import Link from 'components/Link'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import TruLogo from 'components/TruLogo'
import SearchInput from 'components/SearchInput'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import EndUserAvatar from 'components/EndUserAvatar'
import DropdownMenu from 'components/DropdownMenu'
import Navbar from 'components/Navbar'
import PageTab from 'components/PageTab'
import Tooltip from 'components/Tooltip'
import NotificationsButton from 'components/NotificationsButton'
import EndUserLoginDropdown from 'components/EndUserLoginDropdown'
import ChatNotificationsButton from 'components/ChatNotificationsButton'
import APortalWelcomeModal from 'components/APortalWelcomeModal'
import Alert from 'components/Alert'

import './index.sass'

export default function Layout({ location, params, children }){
  const currentUser = useCurrentUser()
  const rootRef = useRef()
  const [welcomeModalIsOpen, openWelcomeModal, closeWelcomeModal] = useToggle()

  useEffect(
    () => {
      // this is here so the overscroll color matches the new page background color
      const style = global.getComputedStyle(rootRef.current)
      global.document.body.style.backgroundColor = style.getPropertyValue('--body-background-color')
      return () => {
        global.document.body.style.backgroundColor = null
      }
    },
    []
  )

  const scrollYRef = useRef(global.window.scrollY)
  useOnScroll(rootRef, () => {
    const previousScrollY = scrollYRef.current
    const scrollY = global.window.scrollY
    scrollYRef.current = scrollY
    const hideNav = scrollY > 200 && previousScrollY < scrollY
    clearTimeout(rootRef.timeout)
    rootRef.timeout = setTimeout(
      () => {
        rootRef.current.classList[hideNav ? 'add' : 'remove']('Layout-TopNav-hidden')
      },
      50
    )
  })

  const searchQuery = (
    location.pathname.startsWith('/search/') &&
    params.searchQuery
  )

  return <div ref={rootRef} className="Layout">
    <APortalWelcomeModal {...{
      open: welcomeModalIsOpen,
      onClose: closeWelcomeModal,
    }}/>
    <TopNav {...{location, currentUser, searchQuery, openWelcomeModal}}/>
    <div className="Layout-main">
      <RenderErrorBoundry>{children}</RenderErrorBoundry>
    </div>
  </div>
}

function RenderErrorBoundry({ children }){
  const [error, dismissError] = useErrorBoundary()
  return error
    ? <div className="Layout-renderError">
      <AppError error={error} onDismiss={dismissError}/>
    </div>
    : children
}

const TopNavPageTab = ({
  children, icon, size, name, href,
  selectedIfExact = true
}) =>
  <PageTab {...{
    href,
    key: `${name}-btn`,
    className: `Layout-TopNav-Button Layout-TopNav-${name.replace(/\s/g, '-')}`,
    selectedIfExact,
  }}>
    {children}
    <Icon type={icon} size={size} />
    <span>&nbsp;&nbsp;{name}</span>
  </PageTab>


function TopNav({location, currentUser, searchQuery, openWelcomeModal}){
  const [, , , toggleInterfaceHelp] = useInterfaceHelpVisibility()
  const isPWA = useIsPWA()
  const loggedIn = !!currentUser
  const onLoginPage = location.pathname.match(/^\/(login|reset-password)$/i)
  const onSignupPage = location.pathname.match(/^\/(signup|join|[^\/]+\/join)$/i)

  usePageKeyPress(
    event => {
      if (!event.altKey && !event.ctrlKey && !event.metaKey && event.key === '?'){
        toggleInterfaceHelp()
        event.preventDefault()
        return false
      }
      if (!event.altKey && !event.shiftKey && !event.ctrlKey && !event.metaKey && event.key === '/'){
        const searchInputs = global.document.body.querySelectorAll('.Layout-SearchBar input')
        const searchInput = Array.from(searchInputs).find(i => i.getBoundingClientRect().y > 0)
        if (searchInput) searchInput.focus()
        event.preventDefault()
        return false
      }
    },
    []
  )

  const buttons = size => {
    const orgListPageButton = <InterfaceHelp inside {...{
      title: `${PNFO.plural}`,
      content: (
        `Your ${PNFO.plural} are listed under the "Member" tab. All ` +
        `Public ${PNFO.plural} are searchable on the right.`
      ),
    }}>
      <TopNavPageTab {...{
        icon: 'hubs', size, name: PNFO.plural,
        href: `/${PNFO.plural}${loggedIn ? '' : '/all'}`
      }}/>
    </InterfaceHelp>
    return isDataYogi
      ? <Fragment>
        <TopNavPageTab {...{icon: 'home', size, name: 'Home', href: '/'}}/>
        {!loggedIn && !onLoginPage &&
          <LoginDropdown {...{location}}>
            <TopNavPageTab {...{icon: 'master-data', size, name: 'Login', href: location.toLogin()}}/>
          </LoginDropdown>
        }
        {!loggedIn && !onSignupPage &&
          <TopNavPageTab {...{icon: 'donate', size, name: 'Signup', href: location.toSignup()}}/>
        }
        {orgListPageButton}
      </Fragment>
      : <Fragment>
        {loggedIn &&
          <InterfaceHelp inside {...{
            title: 'Your Home Feed',
            content: (
              `Your Home Feed is a combination of the curated posts published by ` +
              `all the ${PNFO.plural} you follow`
            ),
          }}>
            <TopNavPageTab {...{icon: 'my-feed', size, name: 'My Feed', href: '/'}}/>
          </InterfaceHelp>
        }
        {orgListPageButton}
        {loggedIn || <LoginAndSignup {...{
          location,
          onLoginPage,
          onSignupPage,
        }}/>}
      </Fragment>
  }

  const searchBar = <SearchBar {...{ location, query: searchQuery }}/>
  const logo = <Link key="logo" href={isDataYogi ? '/DataYogi' : '/'} className="Layout-logo">
    <TruLogo size="sm"/>
  </Link>

  const chatButton = currentUser && !isDataYogi && (
    <InterfaceHelp inside {...{
      title: 'Chat',
      content: (
        `Form deeper connections with others, either privately by DM, with ` +
        `any small group, or the entire ${PNFO.singular}.`
      ),
    }}>
      <ChatNotificationsButton />
    </InterfaceHelp>
  )

  const helpButton = <HelpButton {...{loggedIn, openWelcomeModal}}/>
  return <div className="Layout-TopNav">
    <Navbar className="Layout-TopNav-desktop">
      {isPWA && <PWAControls />}
      {logo}
      {searchBar}
      <OnlineStatus />
      {buttons('lg')}
      {chatButton}
      {currentUser && <CurrentUser {...currentUser}/>}
      {currentUser && <NotificationsButton/>}
      {helpButton}
    </Navbar>
    <Navbar className="Layout-TopNav-mobile">
      {isPWA && <PWAControls />}
      {logo}
      {searchBar}
      <OnlineStatus />
      {currentUser && <CurrentUser {...currentUser}/>}
    </Navbar>
    <Navbar className="Layout-TopNav-mobile">
      {buttons('lg')}
      {chatButton}
      {currentUser && <NotificationsButton/>}
      {helpButton}
    </Navbar>
  </div>
}

function OnlineStatus(){
  const online = useOnlineStatus()
  if (online) return
  return <Alert type="error"><b>OFFLINE</b></Alert>
}

function LoginAndSignup({
  size,
  location,
  onLoginPage,
  onSignupPage,
}){
  return <Fragment>
    <TopNavPageTab {...{icon: 'login', size, name: 'Login', href: location.toLogin()}}/>
    {!onLoginPage && !onSignupPage &&
      <LoginDropdown {...{location}}>
        <PageTab className="Layout-TopNav-LoginDropdown" href={location.toLogin()}>
          <Icon type="login" size={size} />
          <span>&nbsp;&nbsp;Login</span>
        </PageTab>
      </LoginDropdown>
    }
    {!onLoginPage && !onSignupPage &&
      <TopNavPageTab {...{icon: 'user-add', size, name: 'Signup', href: location.toSignup()}}/>
    }
  </Fragment>
}

function SearchBar({location, query}){
  const pageChangeDebounceTimeoutRef = useRef()

  const value = query ? decodeURIComponent(query) : ''

  const whereWeCameFromRef = useRef()
  useEffect(
    () => {
      const pathnameParts = location.pathname.split('/')
      const onSearchPage = pathnameParts[1] === 'search'
      if (!onSearchPage) whereWeCameFromRef.current = location.pathname
    },
    [location.pathname],
  )

  const onClear = useCallback(
    () => {
      history.visit(whereWeCameFromRef.current || `/${PNFO.plural}/all`)
    },
    [],
  )

  const onInput = useCallback(
    query => {
      if (pageChangeDebounceTimeoutRef.current)
        clearTimeout(pageChangeDebounceTimeoutRef.current)
      pageChangeDebounceTimeoutRef.current = setTimeout(
        () => {
          if (query) {
            history.pushState(
              null,
              `search - ${query}`,
              `/search/${encodeURIComponent(query)}`
            )
          }else{
            onClear()
          }
        },
        500
      )
    },
    [location.pathname]
  )
  return <SearchInput {...{
    value,
    onInput,
    onClear,
    className: 'Layout-SearchBar',
    placeholder: 'Search…',
    interfaceHelp: {
      position: 'tl',
      inside: true,
      title: 'Search',
      content: `Search all of ${APP_NAME}`,
    },
  }}/>
}

function CurrentUser(){
  const { myPublicProfile } = useMyPublicProfile('Layout.CurrentUser')

  if (!myPublicProfile) return null

  const dropdownOptions = []
  dropdownOptions.push({
    value: 'Profile',
    href: `${publicProfileToPathname(myPublicProfile)}${isDataYogi ? '/about' : ''}`,
    help: `View your public profile page here`,
  })
  if (!isDataYogi) dropdownOptions.push({
    value: `Create ${PNFO.singular}`,
    href: `/${PNFO.singular}/new`,
    help: `You can create your own ${PNFO.singular} here`
  })
  dropdownOptions.push({
    value: 'Settings',
    href: '/settings',
    help: `Your account settings are controlled here`,
  })
  dropdownOptions.push({
    value: 'My Data',
    href: '/my-data/defaults',
    help: `Your personal data sharing permissions and settings are controlled here`,
  })
  dropdownOptions.push({
    value: 'SISAs',
    href: '/my-data/sisas',
    help: `The SISAs you have with ${PNFO.plural} are controlled here`,
  })
  dropdownOptions.push('--')
  dropdownOptions.push({
    value: 'Logout',
    onSelect: logout,
    help: `Click here to logout of your account`
  })

  return <InterfaceHelp
    inside
    title="Main Account Menu"
    content={
      <div>
        <table>
          {dropdownOptions.filter(o => o && o.help).map(option =>
            <tr>
              <th style={{textAlign: 'right'}}>{option.value}</th>
              <td>{option.help}</td>
            </tr>
          )}
        </table>
      </div>
    }
  >
    <DropdownMenu
      className="Layout-CurrentUser-Menu"
      options={dropdownOptions}
      rightAligned
    >

      <LinkToPublicProfile
        publicProfile={myPublicProfile}
        className="Layout-CurrentUser-avatar"
      >
        <EndUserAvatar {...{ publicProfile: myPublicProfile, size: 'sm' }} />
      </LinkToPublicProfile>
    </DropdownMenu>
  </InterfaceHelp>
}

const PWA_TOOL_TIPS = {
  back: (
    'back' +
    (isMobile ? '' : ` ${metaKeyCharacter}[`)
  ),
  forward: (
    'forward' +
    (isMobile ? '' : ` ${metaKeyCharacter}]`)
  ),
  reload: (
    'reload' +
    (isMobile ? '' : ` ${metaKeyCharacter}R`)
  ),
}

const PWAControls = () =>
  <Fragment>
    <Tooltip {...{
      text: PWA_TOOL_TIPS.back,
      key: 'back',
    }}>
      <IconButton {...{
        className: 'Layout-backButton',
        type: 'left-open',
        disabled: !history.canGoBack,
        onClick: () => history.back(),
      }}/>
    </Tooltip>
    <Tooltip {...{
      text: PWA_TOOL_TIPS.forward,
      key: 'forward',
    }}>
      <IconButton {...{
        className: 'Layout-forwardButton',
        disabled: !history.canGoForward,
        type: 'right-open',
        onClick: () => history.forward(),
      }}/>
    </Tooltip>
    <Tooltip {...{
      text: PWA_TOOL_TIPS.reload,
      key: 'reload',
    }}>
      <IconButton {...{
        className: 'Layout-reloadButton',
        type: 'history',
        onClick: () => history.reload(),
      }}/>
    </Tooltip>
  </Fragment>


function LoginDropdown({ children, location }){
  const buttonRef = useRef()
  const [isOpen, open, close] = useToggle(false)
  const onClick = useCallback(
    event => {
      if (event.altKey || event.shiftKey || event.ctrlKey || event.metaKey) return
      event.preventDefault()
      open()
    },
    [open]
  )
  const button = cloneElement(children, {
    ref: buttonRef,
    onClick,
  })
  return <Fragment>
    {button}
    <EndUserLoginDropdown rightAligned {...{
      open: isOpen,
      onClose: close,
      anchorRef: buttonRef,
      location,
    }}/>
  </Fragment>
}

const HelpIconButton = props =>
  <IconButton type="help-circled" size="lg" {...props} />

function HelpButton({ openWelcomeModal }){
  const [interfaceHelpVisible, showInterfaceHelp, hideInterfaceHelp] = useInterfaceHelpVisibility()
  const { recentOrganizationApikeys = [] } = useRecentOrganizations()
  const organizationApikey = recentOrganizationApikeys[0] || 'TruSocial'

  const options = []
  options.push({
    value: (
      `Help Mode ` +
      (interfaceHelpVisible ? 'Off' : 'On')
    ),
    onSelect: interfaceHelpVisible ? hideInterfaceHelp : showInterfaceHelp,
  })
  options.push('--')
  if (!isDataYogi) options.push({
    value: 'Replay Walkthrough',
    href: `/${organizationApikey}?iw=0`,
  })
  options.push({
    value: 'Replay Intro Video',
    onSelect: openWelcomeModal,
  })
  options.push({
    value: 'Frequently Asked Questions',
    href: '/faq',
  })

  return <DropdownMenu
    className="Layout-HelpDropdownButton"
    type="menu"
    options={options}
    rightAligned
  >
    <HelpIconButton blue={interfaceHelpVisible}/>
  </DropdownMenu>
}
