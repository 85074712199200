import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import Header from 'components/Header'
import ButtonRow from 'components/ButtonRow'
import InterfaceHelp from 'components/InterfaceHelp'
import './index.sass'

export default function HeaderWithButtons({
  className = '',
  children,
  buttons,
  underlined,
  interfaceHelp,
  ...props
}){
  // className = `HeaderWithButtons ${className}`
  // if (underlined) className += ' HeaderWithButtons-underlined'
  return <InterfaceHelp {...{
    // inside: true,
    component: 'div',
    className: classNames(
      'HeaderWithButtons',
      {className, underlined},
    ),
    ...interfaceHelp,
  }}>
    <Header {...props}>{children}</Header>
    <ButtonRow>{buttons}</ButtonRow>
  </InterfaceHelp>
}

HeaderWithButtons.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  buttons: PropTypes.node,
  underlined: PropTypes.bool,
  interfaceHelp: PropTypes.node,
}
