import { h, Fragment } from 'preact'
import { useState, useRef } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { isInvalidPassword } from 'lib/validators'
import useAutoFocus from 'lib/useAutoFocusHook'
import useToggle from 'lib/useToggleHook'

import { useSignup, signUp } from 'resources/auth'
import { toHref } from 'resources/location'

import Form from 'components/Form'
import Link from 'components/Link'
import Header from 'components/Header'
import Button from 'components/Button'
import StyleishModal from 'components/StyleishModal'
import APortalAuthForm from 'components/APortalAuthForm'
import TextInput from 'components/TextInput'
import UsernameInput from 'components/UsernameInput'
import PhoneInput from 'components/PhoneInput'
import JlincTerms from 'components/JlincTerms'
import Checkbox from 'components/Checkbox'
import Spinner from 'components/Spinner'
import Captcha from 'components/Captcha'
import './index.sass'

export default function APortalSignupForm({
  className,
  disabled,
  source = 'portalsSignupPage',
  organizationInviteToken,
  organization,
  destinationPath,
}){
  const joinOrganization = organization && organization.apikey

  if (!destinationPath && organization) destinationPath =
    toHref(`/${joinOrganization}`, { i: organizationInviteToken })

  const {
    login,
    setLogin,
    username,
    setUsername,
    setSignUpError,
    signingUp,
  } = useSignup()

  const [optedForEmail, setOptedForEmail] = useState(
    login && login.includes('@')
  )
  const [showingConfirmModal, showConfirmModal, hideConfirmModal] = useToggle()
  const [agreeToTermsChecked, setAgreeToTermsChecked] = useState()

  const toggleOptedForEmail = () => {
    setOptedForEmail(!optedForEmail)
    setLogin('')
  }

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [captchaSolve, setCaptchaSolve] = useState('')

  const usernameInputRef = useRef()
  useAutoFocus(usernameInputRef)

  if (signingUp) disabled = true
  const submitting = !!signingUp

  const checkboxLabel = <span>
    I am over 13 years old & agree to the <Link onClick={showConfirmModal} type="link">terms</Link>
  </span>

  return <Fragment>
    <TermsModal {...{
      open: showingConfirmModal,
      onClose: hideConfirmModal,
    }} />
    <APortalAuthForm
      className={classNames('APortalSignupForm', { className })}
    >
      <IntroContent {...{ organization, invited: !!organizationInviteToken }}/>
      <Form {...{
        disabled,
        submitting,
        onSubmit(){
          const value = {
            username,
            [optedForEmail ? 'email' : 'mobile']: login,
            password,
          }
          const error = validate({
            optedForEmail,
            passwordConfirmation,
            agreeToTermsChecked,
            ...value
          })
          if (error) {
            setSignUpError(error)
          } else signUp({
            ...value,
            captchaSolve,
            source,
            joinOrganization,
            organizationInviteToken,
          })
        },
      }}>
        <Form.Row>
          <Form.Item>
            <Form.Label>USERNAME</Form.Label>
            <UsernameInput {...{
              ref: usernameInputRef,
              disabled,
              value: username,
              onChange: setUsername,
              name: 'username',
              autocomplete: 'username',
              required: true,
              placeholder: 'YourUsername',
              lpignore: true,
            }}/>
          </Form.Item>
        </Form.Row>
        <Form.Row className="APortalSignupForm-emailOrMobile">
          <Button type="link" className="APortalSignupForm-toggleOptedForEmail" onClick={toggleOptedForEmail}>
            <small>{`use ${optedForEmail ? 'mobile phone' : 'email'} instead`}</small>
          </Button>
          <Form.Item>
            <Form.Label>
              {optedForEmail ? 'EMAIL' : 'MOBILE PHONE'}
            </Form.Label>
            {optedForEmail
              ? <TextInput {...{
                disabled,
                value: login,
                onInput: setLogin,
                name: 'email',
                autocomplete: 'email',
                required: true,
                placeholder: 'you@example.com',
                lpignore: false,
              }}/>
              : <PhoneInput {...{
                disabled,
                value: login,
                onInput: setLogin,
                name: 'mobile',
                autocomplete: 'tel',
                required: true,
                lpignore: true,
              }}/>
            }
          </Form.Item>
        </Form.Row>
        <APortalAuthForm.Passwords {...{
          disabled,
          password,
          setPassword,
          passwordConfirmation,
          setPasswordConfirmation,
          showPassword,
          setShowPassword,
        }}/>
        <Form.Row>
          <Form.Item>
            <Checkbox
              label={checkboxLabel}
              onChange={setAgreeToTermsChecked}
              value={agreeToTermsChecked}
              required
            />
          </ Form.Item>
        </Form.Row>
        <Form.Row>
          <Form.Item>
            <Captcha {...{
              value: captchaSolve,
              onChange: setCaptchaSolve,
              submitting,
            }}/>
          </Form.Item>
        </Form.Row>
        <Form.Row>
          <Button submit fat block {...{
            disabled: disabled,
            type: 'primary',
            value: (signingUp
              ? <span><Spinner />&nbsp;Signing Up…</span>
              : 'Sign up' + (organization ? ` and join ${organization.name}` : '')
            ),
          }}/>
        </Form.Row>
      </Form>
      <APortalAuthForm.Footer>
        <span>Already have an account? </span>
        <Link {...{
          type: 'text',
          pathname: '/login',
          query: {r: destinationPath, o: organization && organization.apikey}
        }}>Login</Link>.
      </APortalAuthForm.Footer>
      {isDataYogi &&
        <APortalAuthForm.Footer>
          <Link type="text" href="https://www.datayogi.me/termsofservice" newWindow>terms of service</Link>
        </APortalAuthForm.Footer>
      }
    </APortalAuthForm>
  </Fragment>
}

APortalSignupForm.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  source: PropTypes.string,
  destinationPath: PropTypes.string,
  organizationInviteToken: PropTypes.string,
  organization: PropTypes.shape({
    apikey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    users_can_request_membership: PropTypes.bool.isRequired,
  }),
}

function IntroContent({ organization, invited }) {
  // const { organization = {} } = useOrganization(joinOrganizationApikey, 'APortalSignupForm')
  // const { invitedToOrganization } = useInvitedToOrganization(joinOrganizationApikey)
  return <APortalAuthForm.Intro>
    <APortalAuthForm.Logo {...{organization}}/>
    {organization &&
      <Header size="xl">
        {`${invited ? 'You have been invited to join' : 'Join'} ${organization.name}`}
      </Header>
    }
    <p className="APortalSignupForm-introText">
      {`Create a free account on ${APP_NAME}`}
      {organization && ' to join'}
    </p>
  </APortalAuthForm.Intro>
}

function validate({ optedForEmail, username, email, mobile, password, passwordConfirmation, agreeToTermsChecked }){
  if (!username) return 'Username is required'
  if (optedForEmail && !email) return 'Email is required'
  if (!optedForEmail && !mobile) return 'Mobile is required'
  if (!password) return 'Password is required'

  const badUsername = UsernameInput.isInvalid(username)
  if (badUsername) return `Invalid username: ${badUsername}`
  const invalidPassword = isInvalidPassword(password)
  if (invalidPassword) return `Invalid password: ${invalidPassword}`
  if (password !== passwordConfirmation) return 'Passwords do not match'
  if (!agreeToTermsChecked) return 'Please agree to the terms'
}

function TermsModal({
  open,
  onClose,
}) {
  return <StyleishModal {...{open, onClose, className: 'APortalSignupForm-TermsModal', autoFocusWithin: false}}>
    <Header size="lg">Terms</Header>
    {Terms}
    <JlincTerms />
  </StyleishModal>
}

const Terms = isDataYogi
  ? <Fragment>
    <p>
      {APP_NAME} provides a social media platform where you control your own data and
      attention. {APP_NAME} does not sell your data, participate in 'Adtech' advertising, or use algorithms to
      manipulate your attention.
    </p>
    <p>
      I authorise {APP_NAME} to operate a data service for me, as my agent, where I control my own
      data, relationships, and intent.
    </p>
  </Fragment>
  : <Fragment>
    <p>
      {LEGAL_APP_NAME} provides a social media platform where you control your own data and attention.
      {APP_NAME} does not sell your data, participate in 'Adtech' targeted advertising, or use algorithms
      to manipulate your attention for advertising.
    </p>
    <p>
      I authorize {LEGAL_APP_NAME} to operate a data service for me, as my agent, where I control my own
      data, relationships, and attention.
    </p>
  </Fragment>
